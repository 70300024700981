<template>
	<div class="mb-4">
		<div class="d-flex justify-content-between mb-2">
			<!-- TÍTULO -->
			<div>
				<h3>
					{{ $t(title) }}

					<!-- tag de status da tela -->
					<b-badge
						v-if="env == 'development' && devBadge"
						:variant="this.badge[this.devBadge]['type']"
						v-b-tooltip.hover
						:title="devBadgeTooltip"
					>
						{{ this.badge[this.devBadge]['text'] }}
					</b-badge>
				</h3>
			</div>

			<!-- BOTÕES DE AÇÃO -->
			<div class="d-flex my-auto">
				<slot />

				<b-button
					v-if="btnPrimary"
					variant="primary"
					class="mr-2"
					@click="primaryEmit"
				>
					<font-awesome-icon :icon="btnPrimaryIcon" />
					{{ $t(btnPrimaryText) }}
				</b-button>

				<b-button
					v-if="linkRetornar"
					variant="danger"
					v-b-tooltip.hover
					:title="$t('GERAL.BTN_VOLTAR_EXPLICACAO')"
					@click="irPara(linkRetornar)"
				>
					<font-awesome-icon icon="fa-solid fa-circle-left" />
				</b-button>
			</div>
		</div>

		<!-- DESCRIÇÃO -->
		<div v-if="description">
			<p>{{ $t(description) }}</p>
		</div>
	</div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas'

export default {
	props: {
		title: { type: String, default: String },
		description: { type: String, default: String },
		btnPrimary: { type: Boolean, default: false },
		btnPrimaryText: { type: String, default: String },
		btnPrimaryIcon: { type: String, default: 'fa-solid fa-file-pen' },
		linkRetornar: { type: String, default: String },

		// props para devs:
		devBadge: { type: String, default: String },
		devBadgeTooltip: { type: String, default: String }
	},
	mounted () { this.env = process.env.NODE_ENV },
	data () {
		return {
			env: '',
			badge: {
				wip: {
					type: 'warning',
					text: '🚧 Em construção'
				},
				bug: {
					type: 'danger',
					text: '🐛 Bugs ativos',
				},
				warning: {
					type: 'warning',
					text: '⚠️ atenção'
				}
			}
		}
	},
	methods: {
		primaryEmit () { this.$emit('primary-method') },
		irPara (rota, id) { rotas.irPara(rota, id) }
	}
}
</script>