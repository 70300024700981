<template>
	<b-card>
		<cabecalho
			title="ACADEMICO.ACADEMICO"
			description="GERAL.LOREM_IPSUM"
			linkRetornar="dashboard"
		/>
		<ul>
			<li><a href="academico/boletim">Boletim</a></li>
			<li><a href="academico/notas-parciais">notas-parciais</a></li>
			<li><a href="academico/parecer-descritivo">parecer-descritivo</a></li>
		</ul>
	</b-card>
</template>
<script>
// Components:
import Cabecalho from '@/components/Cabecalho'

export default {
	components: {
		Cabecalho
	}
}
</script>